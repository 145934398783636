import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import getGithubContributors from "../utils/getGithubContributors";
import shuffleArray from "../utils/shuffleArray";

export class Team extends Component {
  constructor(props) {
    super(props);
    this.state = { contributors: [] };
    this.t = props.t;
  }

  componentDidMount() {
    (async () => {
      const contibs = await getGithubContributors();
      shuffleArray(contibs);
      this.setState({
        ...this.state,
        contributors: contibs,
      });
    })();
  }

  render() {
    return (
      <div id="team" className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>{this.t("Meet the Team")}</h2>
          </div>
          <div id="row">
            <div className="col-xs-12 team">
              <div className="thumbnail">
                {" "}
                <img src="img/team/01.png" alt="..." className="team-img" />
                <div className="caption">
                  <h3>
                    <a
                      href="https://www.linkedin.com/in/pavlobu/"
                      target="_blank"
                    >
                      Pavlo (Paul) Buidenkov
                    </a>
                  </h3>
                  <p>{this.t("Deskreen Creator")}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-8 col-md-offset-2 section-title"
            style={{ marginBottom: "50px", pointerEvents: "none" }}
          >
            <p>{this.t("Hi, I'm Paul - the guy behind Deskreen. I did a lot of planning, research and development to bring Deskreen to life. It was a lot of fun!")}
            </p>
          </div>
          <div id="team-open-source-icon" className="col-xs-12">
            <i className="fa fa-users"></i>
          </div>
          <div className="col-md-8 col-md-offset-2">
            <div className="caption">
              <h3 style={{ marginTop: "10px" }}>
                <a
                  href="https://github.com/pavlobu/deskreen/graphs/contributors"
                  target="_blank"
                >
                  {this.t("Stellar Open-Source Contributors")}
                </a>
              </h3>
            </div>
            <p style={{ marginTop: "20px" }}>{this.t("If you are a developer, consider contributing to Deskreen community on our github page and you will be listed here")}
            </p>
            <div className="row" /* style={{ height: '500px', overflowY: 'scroll' }} */>
              <div class="row image-grid">
                {this.state.contributors.map((c) => {
                  if (c.login === "pavlobu") return <></>;
                  return (
                    <a href={c.html_url} target="_blank">
                      <div class="col-sm-4 col-md-4" style={{ marginBottom: '15px' }}>
                        <div class="panel panel-default">
                          <div class="panel-body">
                            <img
                              alt=""
                              class="img-responsive center-block"
                              src={c.avatar_url}
                              style={{
                                height: "125px",
                              }}
                            />
                          </div>
                          <div class="panel-footer">
                            <i
                              class="fa fa-star contributor-name-decor"
                              aria-hidden="true"
                            ></i>
                            <strong>{c.login}</strong>
                            <i
                              class="fa fa-star contributor-name-decor"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Team);
