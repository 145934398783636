import React, { Component } from "react";
import emoji from "react-easy-emoji";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../i18n";

export class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = { langFlag: "🇺🇸", langSet: "en" };
    this.toggleDisplayLanguagePicker = this.toggleDisplayLanguagePicker.bind(
      this
    );
    this.t = props.t;
  }

  emojiOne(input) {
    return emoji(input, {
      baseUrl: "//cdnjs.cloudflare.com/ajax/libs/emojione/2.2.5/assets/png/",
      size: "",
    });
  }

  toggleDisplayLanguagePicker() {
    console.log("showing display language picker");
  }

  getSetLangFlag() {
    const lang = window.localStorage.getItem("i18nextLng");

    if (lang === "en") {
      return "🇺🇸";
    } else if (lang === "ua") {
      return "🇺🇦";
    } else if (lang === "ru") {
      return "🇷🇺";
    } else if (lang === "zh_CN") {
      return "🇨🇳";
    } else if (lang === "zh_TW") {
      return "🇹🇼";
    } else if (lang === "es") {
      return "🇪🇸";
    } else if (lang === "de") {
      return "🇩🇪";
    } else if (lang === "da") {
      return "🇩🇰";
    } else if (lang === "fi") {
      return "🇫🇮";
    } else if (lang === "ko") {
      return "🇰🇷";
    } else if (lang === "it") {
      return "🇮🇹";
    } else if (lang === "ja") {
      return "🇯🇵";
    } else if (lang === "fr") {
      return "🇫🇷";
    } else if (lang === "sv") {
      return "🇸🇪";
    } else if (lang === "nl") {
      return "🇳🇱";
    }

    return "🇺🇸";
  }

  componentDidMount() {
    const langPath = window.location.href.split(window.location.host)[1];

    let langSet = "en";

    if (langPath.startsWith("/lang-en")) {
      langSet = "en";
    }
    if (langPath.startsWith("/lang-ru")) {
      langSet = "ru";
    }
    if (langPath.startsWith("/lang-ua")) {
      langSet = "ua";
    }
    if (langPath.startsWith("/lang-zh_CN")) {
      langSet = "zh_CN";
    }
    if (langPath.startsWith("/lang-zh_TW")) {
      langSet = "zh_TW";
    }
    if (langPath.startsWith("/lang-es")) {
      langSet = "es";
    }
    if (langPath.startsWith("/lang-de")) {
      langSet = "de";
    }
    if (langPath.startsWith("/lang-da")) {
      langSet = "da";
    }
    if (langPath.startsWith("/lang-fi")) {
      langSet = "fi";
    }
    if (langPath.startsWith("/lang-ko")) {
      langSet = "ko";
    }
    if (langPath.startsWith("/lang-it")) {
      langSet = "it";
    }
    if (langPath.startsWith("/lang-ja")) {
      langSet = "ja";
    }
    if (langPath.startsWith("/lang-fr")) {
      langSet = "fr";
    }
    if (langPath.startsWith("/lang-sv")) {
      langSet = "sv";
    }
    if (langPath.startsWith("/lang-nl")) {
      langSet = "nl";
    }

    this.setState({
      ...this.state,
      langFlag: this.getSetLangFlag(),
      langSet,
    });
  }

  render() {
    const paramsPath = window.location.href.split("?")[1];

    return (
      <nav
        id="menu"
        className="navbar navbar-default navbar-fixed-top smart-scroll navbar-expand-lg"
        style={{ width: "100vw" }}
        // disableAutohide={true}
      >
        <div
          id="language-switch-overlay"
          style={{
            position: "absolute",
            zIndex: 99998,
            display: "none",
            backgroundColor: "rgba(0,0,0,0.3)",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
          }}
        ></div>
        <div className="container" style={{ width: "100vw" }}>
          <div className="col-xs-12 col-md-12 col-lg-11 col-offset-lg-1">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
              <div
                id="navbar-brand-with-donate-button"
                className="navbar-brand page-scroll"
              >
                <div className="row align-items-center deskreen-brand-row">
                  <div
                    className="col col-xs-2"
                    style={{
                      width: "80px",
                    }}
                  >
                    <a
                      id="navbar-brand-with-donate-button"
                      href="#page-top"
                      className="page-scroll"
                    >
                      <div className="col col-xs-1">
                        <img
                          src="img/deskreen-logo-icon_512x512.png"
                          style={{
                            height: "50px",
                            transform: "translateY(-15px)",
                          }}
                        />
                      </div>
                    </a>{" "}
                  </div>
                  <div className="col col-xs-4">
                    <div className="row">
                      <div className="col col-xs-1">
                        <button
                          id="donate-patreon-button"
                          type="button"
                          className="btn btn-custom btn-lg"
                          style={{
                            height: "50px",
                            width: "120px",
                            transform: "translateY(-15px)",
                            borderRadius: "50px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <div className="text-inside-donate-button col align-center">
                            <span style={{ fontWeight: 900 }}>
                              {`${this.t("Donate")} `}{" "}
                            </span>
                            <i
                              className="fa fa-heart"
                              style={{
                                fontSize: "20px",
                                color: "rgb(255,29,29)",
                                transform: "translateY(3px)",
                              }}
                            ></i>
                          </div>
                        </button>
                      </div>
                      <div className="col col-xs-1">
                        <div>
                          <button
                            id="open-toggle-language-switch"
                            type="button"
                            className="btn btn-sm btn-light"
                            style={{
                              height: "50px",
                              width: "50px",
                              transform: "translate(100px, -15px)",
                              borderRadius: "50px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              border: "2px solid rgb(0 8 255 / 48%)",
                              backgroundColor: "transparent",
                            }}
                          >
                            <div className="text-inside-language-button col align-center">
                              <span
                                style={{
                                  fontSize: "30px",
                                }}
                              >
                                {this.emojiOne(this.getSetLangFlag())}
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className="col col-xs-1">
                        <div
                          id="language-switch"
                          style={{
                            position: "absolute",
                            height: "350px",
                            width: "250px",
                            transform: "translate(10px, -15px)",
                            zIndex: 999999999,
                            borderRadius: "20px",
                            padding: "5px",
                            border: "0px solid",
                            backgroundColor: "white",
                            display: "none",
                          }}
                        >
                          <Link
                            to={
                              paramsPath ? `/lang-en?${paramsPath}` : "/lang-en"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "en"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-en";

                                i18n.changeLanguage("en");
                                window.localStorage.setItem("i18nextLng", "en");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "en",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇺🇸")} English
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-ua?${paramsPath}` : "/lang-ua"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "ua"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-ua";

                                i18n.changeLanguage("ua");
                                window.localStorage.setItem("i18nextLng", "ua");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "ua",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇺🇦")} Українська
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-ru?${paramsPath}` : "/lang-ru"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "ru"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-ru";

                                i18n.changeLanguage("ru");
                                window.localStorage.setItem("i18nextLng", "ru");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "ru",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇷🇺")} Русский
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath
                                ? `/lang-zh_CN?${paramsPath}`
                                : "/lang-zh_CN"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "zh_CN"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-zh_CN";

                                i18n.changeLanguage("zh_CN");
                                window.localStorage.setItem(
                                  "i18nextLng",
                                  "zh_CN"
                                );
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "zh_CN",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇨🇳")} 简体中文
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath
                                ? `/lang-zh_TW?${paramsPath}`
                                : "/lang-zh_TW"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "zh_TW"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-zh_TW";

                                i18n.changeLanguage("zh_TW");
                                window.localStorage.setItem(
                                  "i18nextLng",
                                  "zh_TW"
                                );
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "zh_TW",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇹🇼")} 繁體中文
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-es?${paramsPath}` : "/lang-es"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "es"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-es";

                                i18n.changeLanguage("es");
                                window.localStorage.setItem("i18nextLng", "es");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "es",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇪🇸")} Español
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-da?${paramsPath}` : "/lang-da"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "da"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-da";

                                i18n.changeLanguage("da");
                                window.localStorage.setItem("i18nextLng", "da");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "da",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇩🇰")} Dansk
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-de?${paramsPath}` : "/lang-de"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "de"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-de";

                                i18n.changeLanguage("de");
                                window.localStorage.setItem("i18nextLng", "de");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "de",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇩🇪")} Deutsch
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-fi?${paramsPath}` : "/lang-fi"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "fi"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-de";

                                i18n.changeLanguage("fi");
                                window.localStorage.setItem("i18nextLng", "fi");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "fi",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇫🇮")} Suomi
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-ko?${paramsPath}` : "/lang-ko"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "ko"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-de";

                                i18n.changeLanguage("ko");
                                window.localStorage.setItem("i18nextLng", "ko");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "ko",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇰🇷")} 한국어
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-it?${paramsPath}` : "/lang-it"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "it"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-de";

                                i18n.changeLanguage("it");
                                window.localStorage.setItem("i18nextLng", "it");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "it",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇮🇹")} Italiano
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-ja?${paramsPath}` : "/lang-ja"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "ja"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-de";

                                i18n.changeLanguage("ja");
                                window.localStorage.setItem("i18nextLng", "ja");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "ja",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇯🇵")} 日本語
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-fr?${paramsPath}` : "/lang-fr"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "fr"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-de";

                                i18n.changeLanguage("fr");
                                window.localStorage.setItem("i18nextLng", "fr");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "fr",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇫🇷")} Français
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-sv?${paramsPath}` : "/lang-sv"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "sv"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-de";

                                i18n.changeLanguage("sv");
                                window.localStorage.setItem("i18nextLng", "sv");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "sv",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇸🇪")} Svenska
                              </span>
                            </button>
                          </Link>
                          <Link
                            to={
                              paramsPath ? `/lang-nl?${paramsPath}` : "/lang-nl"
                            }
                          >
                            <button
                              className={`btn btn-sm ${
                                this.state.langSet === "nl"
                                  ? "btn-primary"
                                  : "btn-light"
                              }`}
                              role="button"
                              style={{
                                margin: "3px",
                                borderRadius: "50px",
                                border: "0px solid",
                              }}
                              onClick={() => {
                                // window.location.href = "/lang-de";

                                i18n.changeLanguage("nl");
                                window.localStorage.setItem("i18nextLng", "nl");
                                document.getElementById(
                                  "language-switch-overlay"
                                ).style.display = "none";
                                document.getElementById(
                                  "language-switch"
                                ).style.display = "none";
                                this.setState({
                                  ...this.state,
                                  langSet: "nl",
                                });
                              }}
                            >
                              <span
                                id="english-lang-switch-btn"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {emoji("🇳🇱")} Holländska
                              </span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul
                className="nav navbar-nav navbar-right"
                style={
                  {
                    // transform: "translateX(80px)"
                  }
                }
              >
                <li>
                  <a href="#page-top" className="page-scroll">
                    {this.t("Download")}
                  </a>
                </li>
                <li>
                  <a href="#features" className="page-scroll">
                    {this.t("Features")}
                  </a>
                </li>
                <li>
                  <a href="#about" className="page-scroll">
                    {this.t("About")}
                  </a>
                </li>
                <li>
                  <a href="#video-demos" className="page-scroll">
                    {this.t("Demos")}
                  </a>
                </li>
                <li>
                  <a href="#howtos" className="page-scroll">
                    {this.t("How to")}
                  </a>
                </li>
                <li>
                  <a href="#faq" className="page-scroll">
                    {this.t("FAQ")}
                  </a>
                </li>
                <li>
                  <a href="#testimonials" className="page-scroll">
                    {this.t("Purpose")}
                  </a>
                </li>
                <li>
                  <a href="#team" className="page-scroll">
                    {this.t("Team")}
                  </a>
                </li>
                <li>
                  <a href="#contact" className="page-scroll">
                    {this.t("Contribute")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default withNamespaces()(Navigation);
