import axios from "axios";

const githubApiRepoTagsUrl =
  "https://api.github.com/repos/pavlobu/deskreen/releases/latest";

export default async function getLatestReleaseDownloadUrls() {
  const response = await axios({
    url: githubApiRepoTagsUrl,
    method: "get",
    headers: { "User-Agent": "node.js" },
  });

  const assetAppImage = response.data.assets.find((asset) => {
    return asset.browser_download_url.endsWith('.AppImage');
  });
  const assetDeb = response.data.assets.find((asset) => {
    return asset.browser_download_url.endsWith('.deb');
  });
  const assetSetupExe = response.data.assets.find((asset) => {
    return asset.browser_download_url.endsWith('.Setup.'+ response.data.tag_name.slice(1) + '.exe');
  });
  const assetDmg = response.data.assets.find((asset) => {
    return asset.browser_download_url.endsWith('.dmg');
  });

  return {
    win: assetSetupExe.browser_download_url,
    mac: assetDmg.browser_download_url,
    deb: assetDeb.browser_download_url,
    appImage: assetAppImage.browser_download_url,
  };
}
