import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withNamespaces } from "react-i18next";

export class Faq extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  componentDidMount() {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  }

  buildFaqLink(link) {
    return window.location.origin + "/?#" + link;
  }

  render() {
    return (
      <div id="faq" className="text-center" style={{ width: "100%" }}>
        <div className="container">
          <div className="section-title-faq">
            <h2>{this.t("Frequenty Asked Questions")}</h2>
            <hr id="faq-1" className="custom-hr-blue-how-to-faq" />
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="left">
              <CopyToClipboard text={this.buildFaqLink("faq-1")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Is having a Virtual Display Adapter absolutely necessary when using Deskreen?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "No it is not necessary. The screen mirroring and sharing single application window functionality of Deskreen are working without any need for Virtual Display Adapter. The Virtual Display Plug is needed only if you want to extend your computer desktop to have a full secondary screen experience with Deskreen. Or if you are an experienced user of your operating system, you can find solutions on how to create a virtual display without a need for Virtual Display Adapter here"
                )}: `}
                <a
                  href="https://github.com/pavlobu/deskreen/discussions/86"
                  target="_blank"
                >
                  https://github.com/pavlobu/deskreen/discussions/86
                </a>
              </p>
              <hr id="faq-2" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-2")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "If the screen viewing receiving device has no camera can Deskreen work without a scanning QR code?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "Yes you can use Deskreen if your receiving device does not have a camera. You just need to copy a full address displayed under a QR code in blue button (click to copy) and paste or type it in a web browser address bar completely, then open that page. For example if you see this in the blue button under QR code (your address will be different!) - http//192.168.1.143131/123456 then it you should type it in your browser address bar on a screen viewing device (tablet, smartphone etc.)."
                )}
              </p>
              <div className="row">
                <div className="col-12">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                  ></script>
                  <ins
                    class="adsbygoogle"
                    style={{ display: "block", textAlign: "center" }}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-6468756664607050"
                    data-ad-slot="5089227267"
                  ></ins>
                  {/* <script>
                  (adsbygoogle = window.adsbygoogle || []).push({});
                </script> */}
                </div>
              </div>
              <hr id="faq-3" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-3")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "I bought a Virtual Display Plug, plugged it into my computer's available display port but Deskreen does not allow me to use a virtual monitor option in the app. What should I do?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "There is no option for that in the app. Secondary Screen viewing is done the same way as screen mirroring. When you’ve plugged-in a Virtual Display Adapter into your computer, it will create a virtual display. Then on step two (after scanning QR code), you can choose an “Entire Screen” option to share and select a new virtual display that appeared along with other displays, after  you've inserted a Virtual Display Adapter."
                )}
              </p>
              <hr id="faq-4" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-4")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Deskreen doesn’t work for me, it won't connect either to my phone or my tablet, all in the same WiFi / LAN. What should I do?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "You may have an antivirus blocking Deskreen app on your computer or have a firewall enabled that prevents any connection between the Deskreen app and your viewing device. Here is how you can troubleshoot that you have a firewall or antivirus blocking Deskreen"
                )}:`}
              </p>
              <p className="howto-step-guide">
                <strong>1. </strong>
                {this.t(
                  "Open a web browser on the same computer where you run the Deskreen app."
                )}
              </p>
              <p className="howto-step-guide">
                <strong>2. </strong>
                {this.t(
                  "Then in the address bar of the web browser type a slightly different address than what you see under QR code in the Deskreen app (blue button under QR code). For example if the address is (your address will be different!) http//192.168.1.143131/123456 then you need to type http//127.0.0.13131/123456. Please note that 3131/123456 should be the same as you see under QR code! The 127.0.0.1 is a localhost of your computer."
                )}
              </p>
              <p className="howto-step-guide">
                <strong>3. </strong>
                {this.t(
                  "Then when you’ve typed the address correctly and opened a web page, the Deskreen viewer should open in that browser page and you should be able to connect Deskreen with no issues. But it will be only working on your computer in that browser window. If that worked, it means that you have a firewall blocking the Deskreen between your computer and device you are trying to connect with (tablet, phone etc)."
                )}
              </p>
              <p className="howto-step-guide">
                <strong>4. </strong>
                {this.t(
                  "If the web page with Deskreen viewer didn’t open at all on your computer, then this could be an antivirus blocking Deskreen app."
                )}
              </p>
              <p className="howto-step-guide">
                {`${this.t(
                  "If it worked and you’ve managed to start a screen sharing session, on your computer, but can't do the same with your phone or tablet,  then this is an indication that you have Deskreen being blocked on your computer or local network. Note that the router may be also having a firewall enabled that is blocking connection between your computer and viewing device (tablet, phone etc.). Antivirus or firewalls may be still blocking Deskreen on your computer. We can’t help you in that case, you need to find a solution on the internet on how to disable the antivirus or firewall on your computer. Try google search how to disable a firewall on your system or disable antivirus for Deskreen. Deskreen works for most users. Here is an example of discussion on how the troubleshooting was done and Deskreen eventually worked"
                )}: `}
                <a
                  href="https://www.reddit.com/r/javascript/comments/l9ldv8/i_created_deskreen_this_is_a_free_open_source/gln75k3?utm_source=share&utm_medium=web2x&context=3"
                  target="_blank"
                >
                  {this.t("click to see a discussion")}
                </a>
                .
              </p>
              <div className="row">
                <div className="col-12">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                  ></script>
                  <ins
                    class="adsbygoogle"
                    style={{ display: "block", textAlign: "center" }}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-6468756664607050"
                    data-ad-slot="5089227267"
                  ></ins>
                  {/* <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script> */}
                </div>
              </div>
              <hr id="faq-5" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-5")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Can I use multiple screens and devices connected with Deskreen at the same time?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "Yes, with Deskreen you can share your screen or app window to multiple devices at the same time. As many as you want! Keep in mind that your computer should be powerful enough to handle that many simultaneous connections to perform smoothly."
                )}
              </p>
              <hr id="faq-6" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-6")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Can I use a Smart TV as a screen viewing device with Deskreen?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t("Yes. Here is how")}: `}
              </p>
              <p className="howto-step-guide">
                <strong>1. </strong>
                {this.t(
                  "You need to have a Chrome or Firefox browser installed on your Smart TV. Other browsers may not work with Deskreen. Google search on how to install Chrome or Firefox on your Smart TV."
                )}
              </p>
              <p className="howto-step-guide">
                <strong>2. </strong>
                {this.t(
                  "You need to connect your smart TV and computer where Deskreen runs to the same WiFi (or LAN)."
                )}
              </p>
              <p className="howto-step-guide">
                <strong>3. </strong>
                {this.t(
                  "Open Chrome or Firefox on your Smart TV and then type an address that you see under QR code in the blue button inside a browser address bar. For example this (your address will be different!) http//192.168.1.143131/123456 , and open that page in your Smart TV web browser."
                )}
              </p>
              <p className="howto-step-guide">
                {this.t(
                  "Then you can bookmark this page in your Smart TV browser and just retype the last 6 digits every time you need to connect to Deskreen again. For example in this address (your address will be different!) http//192.168.1.143131/123456 , last 6 digits you will need to retype are 123456."
                )}
              </p>
              <hr id="faq-7" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-7")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Can I install Deskreen on my phone? Where can I download an .apk file?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "No. Deskreen works only on computers with Windows, MacOS or Linux. But you can use your phone as a screen viewing device. For that you only need a web browser on your phone to see your computer screen."
                )}
              </p>
              <div className="row">
                <div className="col-12">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                  ></script>
                  <ins
                    class="adsbygoogle"
                    style={{ display: "block", textAlign: "center" }}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-6468756664607050"
                    data-ad-slot="5089227267"
                  ></ins>
                  {/* <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script> */}
                </div>
              </div>
              <hr id="faq-8" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-8")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Will Deskreen work on my 1st or 2nd generation IPads? Will Deskreen work on some really old device?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "No. At the moment we are looking into solutions on how to add a support for really old screen viewing devices with old and outdated browsers. The problem is that WebRTC is not supported in old outdated web browsers, but we may fix this in the future. In future releases this issue may be fixed. Stay tuned. You can find updates in the following link"
                )}: `}
                <a
                  href="https://github.com/pavlobu/deskreen/issues/90"
                  target="_blank"
                >
                  https://github.com/pavlobu/deskreen/issues/90
                </a>
              </p>
              <hr id="faq-9" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-9")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "How is Deskreen different from other commercial apps or VNC based solutions?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "Deskreen is an open source application that is easy to use. It uses state of the art video streaming web technology and is not collecting or sending your personal data anywhere. Deskreen works faster and smoother than VNC based solutions. Also with Deskreen you can turn any device into a teleprompter. Deskreen supports sharing a single application window to screen viewing device. Deskreen may be used to make any device as a secondary screen for your computer. Also Deskreen works over WiFi."
                )}
              </p>
              <hr id="faq-10" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-10")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Can I connect my tablet with my computer with a USB or USB-C cable to use Deskreen instead of using WiFi or LAN?"
                  )}
                </h3>
              </CopyToClipboard>
              <a
                style={{ fontSize: "20px" }}
                target="_blank"
                href="https://www.amazon.com/GL-iNet-GL-AR750S-Ext-pre-Installed-Cloudflare-Included/dp/B07GBXMBQF/ref=sr_1_2?dchild=1&amp;keywords=Slate+%28GL-AR750S-Ext%29&amp;qid=1613736602&amp;sr=8-2&_encoding=UTF8&tag=deskreen-20&linkCode=ur2&linkId=90073afc1054aa95a24d20a24648c9ca&camp=1789&creative=9325"
              >
                {this.t(
                  "Shop for Recommended Travel USB Wifi Router on Amazon"
                )}
              </a>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "Yes. But this is a slightly complicated thing to do. We highly recommend you to use Deskreen with WiFi or LAN. Otherwise, if you really want it to work with a USB cable, you need to enable tethering between your computer and tablet in such a way so your computer shares the Internet with your tablet over USB. For example let’s say you have an IPad and Mac. You can connect them with USB cable and enable a built in feature of MacOS to do Internet sharing over USB to your IPad. Then you need to open a web browser on your IPad. Then type a slightly different address in the IPad web browser address bar than what you see under Deskreen QR code. For example if you see (your address will be different!) http//192.168.1.143131/123456 under QR code, on your IPad you will need to type http//192.168.2.13131/123456 . And then open the page. (Notice 192.168.2.1 - this only was tested and worked with IPads and Mac. Your case may be different) . Then Deskreen should run over USB in this case with no need for WiFi. At least it worked for some users. On Windows or Linux tethering devices over USB and sharing the Internet to them may be different. So you need to find your way on how to do this. Try google search to find out how to do this. This process may take you a lot of time and probably with no outcome, so we still highly recommend you to use Deskreen with WiFi or LAN."
                )}
              </p>
              <a
                style={{ fontSize: "20px" }}
                target="_blank"
                href="https://www.amazon.com/GL-iNet-GL-AR750S-Ext-pre-Installed-Cloudflare-Included/dp/B07GBXMBQF/ref=sr_1_2?dchild=1&amp;keywords=Slate+%28GL-AR750S-Ext%29&amp;qid=1613736602&amp;sr=8-2&_encoding=UTF8&tag=deskreen-20&linkCode=ur2&linkId=90073afc1054aa95a24d20a24648c9ca&camp=1789&creative=9325"
              >
                {this.t(
                  "Shop for Recommended Travel USB Wifi Router on Amazon"
                )}
              </a>
              <div className="row">
                <div className="col-12">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                  ></script>
                  <ins
                    class="adsbygoogle"
                    style={{ display: "block", textAlign: "center" }}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-6468756664607050"
                    data-ad-slot="5089227267"
                  ></ins>
                  {/* <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script> */}
                </div>
              </div>
              <hr id="faq-11" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-11")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Why is my virtual display blurry when I’m using Deskreen with a Virtual Display Plug?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "First of all you need to be sure you have the following"
                )}: `}
              </p>
              <p className="howto-step-guide">
                <strong>1.&nbsp;</strong>
                {this.t(
                  "You have a good local internet speed between your computer and screen viewing device (phone, tablet etc.). Make sure that you are close to the WiFi router and the router is not very old (supports 5Ghz connection). For LAN it is the same slow and old network cards may perform poorly with screen sharing using Deskreen."
                )}
              </p>
              <p className="howto-step-guide">
                <strong>2.&nbsp;</strong>
                {this.t(
                  "You are not using an old computer or old viewing device (tablet, phone etc.) with weak hardware."
                )}
              </p>
              <p className="howto-step-guide">
                {`${this.t(
                  "If that is not your case, here is what you can try to do. You need to set a specific screen resolution for your Virtual Display that will fit your display. Try to go to your system Display settings and play around with virtual display screen resolutions to eliminate blurry virtual display. If your Windows or Linux has a limited number of resolution modes available for virtual display in settings, you may try to google search for other software that will help you to extend a list of possible virtual screen resolutions. For example for MacOS there is a tool called RDM that helps you to have more options of resolutions for your virtual display created with Virtual Display Plug. Here is a link"
                )}: `}
                <a href="https://github.com/avibrazil/RDM">
                  https://github.com/avibrazil/RDM
                </a>
              </p>
              <hr id="faq-12" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-12")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Does Deskreen support touch events when I’m sharing a screen to my tablet or phone? Does it have a remote control functionality?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "No, Deskreen is currently not supporting a functionality to remote control your computer from your tablet or other device. We are considering adding this functionality in later releases. You can make a financial donation to Deskreen to keep us motivated to add this feature in future versions of the app. If you are a developer, you can contribute with code by adding this feature in our GitHub repository."
                )}
              </p>
              <hr id="faq-13" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-13")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Does Deskreen need the Internet access to run? Will it use any Cellular Data if I use my smartphone as a screen viewing device?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "No, Deskreen is designed to use only local private network data WiFi or LAN connections. It may be used at home, office, cafe or other local networks. It does not send your personal data anywhere to the remote servers and does not consume any Cellular Data."
                )}
              </p>
              <div className="row">
                <div className="col-12">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                  ></script>
                  <ins
                    class="adsbygoogle"
                    style={{ display: "block", textAlign: "center" }}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-6468756664607050"
                    data-ad-slot="5089227267"
                  ></ins>
                  {/* <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script> */}
                </div>
              </div>
              <hr id="faq-14" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-14")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t("How to reduce latency while using Deskreen?")}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "Deskreen works with state of the art video streaming over network technology. It is using all horse power available on your computer and network to send screen video streams to your screen viewing devices. If you experience any lags or having performance issues with Deskreen there are many reasons why this can be happening. The reasons of latency may be"
                )}:`}
              </p>
              <p className="howto-step-guide">
                <strong>1.&nbsp;</strong>
                {this.t(
                  "You may have a computer or screen viewing device(tablet) that are outdated and have old or slow hardware."
                )}
              </p>
              <p className="howto-step-guide">
                <strong>2.&nbsp;</strong>
                {this.t("You may be too far from your WiFi source.")}
              </p>
              <p className="howto-step-guide">
                <strong>3.&nbsp;</strong>
                {this.t("You may have a slow WiFi router.")}
              </p>
              <p className="howto-step-guide">
                <strong>4.&nbsp;</strong>
                {this.t(
                  "You may have a slow network connection between your computer and screen viewing device."
                )}
              </p>
              <p className="howto-step-guide">
                <strong>5.&nbsp;</strong>
                {this.t(
                  "You may have a large network card traffic usage by downloading a big file from the internet, for example you are downloading a torrent, that is consuming a lot of traffic of your network card."
                )}
              </p>
              <p className="howto-step-guide">
                {this.t(
                  "Trying to eliminate those limitations will result in a better performance of Deskreen in general. The only thing we want to mention about latency is that it will never be so perfect like a real cable connection to an external monitor with video ports such as HDMI, DP etc. Deskreen is doing video streaming over a network, that is why some millisecond delays may occur."
                )}
              </p>
              <hr id="faq-15" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-15")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Can I create a virtual display without a Virtual Display Plug?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "Yes. But you need to be ready and have free time to tinker with your operating system. In our discussions channel on GitHub, you may find solutions that may work for you. Here is a link"
                )}: `}
                <a
                  href="https://github.com/pavlobu/deskreen/discussions/86"
                  target="_blank"
                >
                  https://github.com/pavlobu/deskreen/discussions/86
                </a>
              </p>
              <hr id="faq-16" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-16")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "I’m using a Virtual Display Plug but Deskreen does not see a virtual monitor it created on Windows. However I can see a virtual display in Display settings."
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t("It may be related to this bug")}: `}
                <a href="https://github.com/pavlobu/deskreen/issues/69">
                  https://github.com/pavlobu/deskreen/issues/69
                </a>
              </p>
              <div className="row">
                <div className="col-12">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                  ></script>
                  <ins
                    class="adsbygoogle"
                    style={{ display: "block", textAlign: "center" }}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-6468756664607050"
                    data-ad-slot="5089227267"
                  ></ins>
                  {/* <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script> */}
                </div>
              </div>
              <hr id="faq-17" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-17")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "I’m using a Virtual Display Plug but Deskreen does not see a cursor on the virtual monitor on Windows."
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t("It may be related to this bug")}: `}
                <a href="https://github.com/pavlobu/deskreen/discussions/68#discussioncomment-330357">
                  https://github.com/pavlobu/deskreen/discussions/68#discussioncomment-330357
                </a>
              </p>
              <hr id="faq-18" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-18")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "On my operating system the Deskreen app does not run/install. It is blocked saying that it may have viruses. Does it have a virus? What should I do?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "There are no viruses in the Deskreen installation file, you can check it with antivirus. The reason you see those warnings is that the app is not code signed yet. This means that Deskreen is currently not signed by any trusted certificate authority. But it is still safe to run Deskreen bypassing these blockings. On Mac and Windows there are different ways to bypass these blockings. They are pretty easy to find with a quick google search."
                )}
              </p>
              <hr id="faq-19" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-19")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Is Wayland supported already on Linux when using Deskreen?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "Yes. It is supported from 1.0.3 version. Check by this link"
                )}: `}
                <a href="https://github.com/pavlobu/deskreen/issues/45">
                  https://github.com/pavlobu/deskreen/issues/45
                </a>
              </p>
              <hr id="faq-20" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-20")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Does Deskreen support ARM? Does it run on Raspberry PI?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "Yes. You can compile it yourself or you may find a custom third party build here"
                )}: `}
                <a href="https://github.com/pavlobu/deskreen/issues/59">
                  https://github.com/pavlobu/deskreen/issues/59
                </a>
              </p>
              <hr id="faq-21" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-21")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Are you going to be adding a support for streaming system audio with screen video in future releases of Deskreen?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "Yes. We plan to add support for sharing audio in future releases. Consider contributing financially to keep us motivated to add this feature in future. You can check a discussion by the following link"
                )}: `}
                <a href="https://github.com/pavlobu/deskreen/discussions/92">
                  https://github.com/pavlobu/deskreen/discussions/92
                </a>
              </p>
              <div className="row">
                <div className="col-12">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                  ></script>
                  <ins
                    class="adsbygoogle"
                    style={{ display: "block", textAlign: "center" }}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-6468756664607050"
                    data-ad-slot="5089227267"
                  ></ins>
                  {/* <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script> */}
                </div>
              </div>
              <hr id="faq-22" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-22")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Are you going to be adding virtual display drivers for Deskreen in future releases to remove the need for Virtual Display Adapters?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "Yes. We plan on removing a need for Virtual Display Adapters in future releases. Consider contributing financially to keep us motivated to add this feature in future. You also may find an already working solution for your use case in our discussion thread. Here is a discussion and you may subscribe to it to stay tuned"
                )}: `}
                <a href="https://github.com/pavlobu/deskreen/discussions/86">
                  https://github.com/pavlobu/deskreen/discussions/86
                </a>
              </p>
              <hr id="faq-23" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-23")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "How is Deskreen made? How does Deskreen work? What is a technical stack used in Deskreen? What is under the hood of Deskreen?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {`${this.t(
                  "You may find a high level architecture design document on our GitHub repository here"
                )}: `}
                <a href="https://github.com/pavlobu/deskreen#high-level-architecture-design">
                  https://github.com/pavlobu/deskreen#high-level-architecture-design
                </a>
              </p>
              <hr id="faq-24" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-24")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "On my MacOS the menus in Deskreen are all grayed out. Do you know why?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "We don’t really know for sure why this is happening. But during development this didn’t happen to us. We will be removing menus in MacOS in future releases because they don’t bring any value to the Deskreen app, every necessary functionality will be in Settings - About panel."
                )}
              </p>
              <hr id="faq-25" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-25")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Why does Deskreen use so much CPU or RAM or Network Bandwidth on my computer?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "Deskreen is a software. And software needs the resources of your computer such as RAM and CPU power, Network Bandwidth and electricity power in order to perform smoothly. When you are streaming the entire screen from your computer to a screen viewing device (tablet, smartphone etc.), it uses your computer resources to make that happen. Have you noticed that your computer or phone gets hot when you are doing a video conference call? It is because during a video call there is much processing happening to provide you with a smooth and reliable video conference. The same thing is happening when you are using Deskreen. It is a kind of a “video call”, when you are sharing your computer screen to another device. So that is why it is using so much resources on your computer."
                )}
              </p>
              <div className="row">
                <div className="col-12">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                  ></script>
                  <ins
                    class="adsbygoogle"
                    style={{ display: "block", textAlign: "center" }}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-6468756664607050"
                    data-ad-slot="5089227267"
                  ></ins>
                  {/* <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script> */}
                </div>
              </div>
              <hr id="faq-26" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-26")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Why do I have only the Default Video Player turned on in the Deskreen viewer on my tablet/phone in a web browser? When I flip the video and enter full screen it is not flipped in Default Video Player."
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "You need to use a native web browser on your tablet or smartphone when using Deskreen. Native browsers support featured video player which supports Flip Screen Mode when the browser is in full screen. If you have IPhone or IPad use Safari, which is a native bro. If you are using an Android device, use Chrome. Use these web browsers to enable Flip Screen Mode in full screen."
                )}
              </p>
              <hr id="faq-27" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-27")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "When I do screen sharing with high screen resolution latency becomes high and screen sharing is lagging. What can I do to reduce latency?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "In such case you need to be having a more powerful machine where Deskreen runs on. Deskreen will use more resources with higher resolution because there are more pixels to be shared to viewing device. It will use more CPU, RAM and network bandwidth with higher resolutions. You can reduce resolution to reduce latency."
                )}
              </p>
              <hr id="faq-28" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-28")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "Can I do reverse screen sharing from my phone or tablet to computer while using Deskreen?"
                  )}
                </h3>
              </CopyToClipboard>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "No. Deskreen is a desktop app. It can only share your computer screen to any device with a web browser."
                )}
              </p>
              <hr id="faq-29" className="custom-hr-blue-how-to-faq" />
              <CopyToClipboard text={this.buildFaqLink("faq-29")}>
                <h3>
                  <i className="fa fa-question-circle">&nbsp;</i>
                  {this.t(
                    "How can I use Deskreen between my laptop and my tablet when I'm on a go, or I don't have a WiFi or LAN network?"
                  )}
                </h3>
              </CopyToClipboard>
              <a
                style={{ fontSize: "20px" }}
                target="_blank"
                href="https://www.amazon.com/GL-iNet-GL-AR750S-Ext-pre-Installed-Cloudflare-Included/dp/B07GBXMBQF/ref=sr_1_2?dchild=1&amp;keywords=Slate+%28GL-AR750S-Ext%29&amp;qid=1613736602&amp;sr=8-2&_encoding=UTF8&tag=deskreen-20&linkCode=ur2&linkId=90073afc1054aa95a24d20a24648c9ca&camp=1789&creative=9325"
              >
                {this.t(
                  "Shop for Recommended Travel USB Wifi Router on Amazon"
                )}
              </a>
              <p className="howto-step-guide">
                <i className="fa fa-comment">&nbsp;</i>
                {this.t(
                  "You can buy a small USB powered travel WiFi router to connect Deskreen with your tablet over your private WiFi network. It connects to you computer with USB and can create your private WiFi network anywhere. Then you can connect your tablet and laptop to this travel router and you can use Deskreen with this setup. Or if you have a smartphone, you can create a WiFi hotspot on your phone and connect your tablet and laptop to this WiFi hotspot and use it as a router. Note that Deskreen doesn't use any Celluar Data. When you start Deskreen, it only asks a remote server if there is an update available, but it is a couple of kilobytes only."
                )}
              </p>
              <a
                style={{ fontSize: "20px" }}
                target="_blank"
                href="https://www.amazon.com/GL-iNet-GL-AR750S-Ext-pre-Installed-Cloudflare-Included/dp/B07GBXMBQF/ref=sr_1_2?dchild=1&amp;keywords=Slate+%28GL-AR750S-Ext%29&amp;qid=1613736602&amp;sr=8-2&_encoding=UTF8&tag=deskreen-20&linkCode=ur2&linkId=90073afc1054aa95a24d20a24648c9ca&camp=1789&creative=9325"
              >
                {this.t(
                  "Shop for Recommended Travel USB Wifi Router on Amazon"
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Faq);
