import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

function generateNotSellingTheseNoteComponent(t) {
  return (
    <blockquote className="blockquote warning-blockquote">
      <h4 style={{}}>
        <strong>{`${t("NOTE")}: `}</strong>
        {t(
          "You can buy Virtual Display Adapters on Amazon, Aliexpress, Ebay etc"
        )}
      </h4>
      <a
        target="_blank"
        href="https://www.amazon.com/gp/search?ie=UTF8&tag=deskreen-20&linkCode=ur2&linkId=538bf36bda9d9c218070388885a1f7a2&camp=1789&creative=9325&index=electronics&keywords=Virtual Display Adapter"
      >
        {t("Shop for Virtual Display Adapters on Amazon")}
      </a>
      <img
        src="//ir-na.amazon-adsystem.com/e/ir?t=deskreen-20&l=ur2&o=1"
        width="1"
        height="1"
        border="0"
        alt=""
        style={{ border: "none !important", margin: "0px !important" }}
      />
    </blockquote>
  );
}

function generatePlugImageWithNameComponent(source, name) {
  return (
    <div className="col-xs-12 col-md-4 img-responsive" align="center">
      <img
        src={source}
        style={{
          height: "200px",
          width: "auto",
          marginBottom: "10px",
        }}
      />
      <h4 className="text-center">{name}</h4>
    </div>
  );
}

export class about extends Component {
  
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6" align="center">
              {" "}
              <img
                src="img/intro-bg.jpg"
                className="img-responsive"
                alt=""
                style={{}}
              />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>{this.t("About Deskreen")}</h2>
                <p>
                  {this.t(
                    "Deskreen is a free and open source desktop app that turns any device with a web browser into a secondary screen for your computer over WiFi. Deskreen can be used to mirror entire computer display onto any device screen that has a web browser. Also you can limit Deskreen to select only one application window view to share - very useful for presentation purposes. The best feature of Deskreen is to use any device as a secondary screen! To have a true extended desktop experience Deskreen should be used with Virtual Display Adapter. Also without any need for Virtual Display Adapter you can have a teleprompter with Deskreen using your tablet or smartphone. For that Deskreen has Flip Screen Mode that mirrors screen (aka. flip screen horizontally)."
                  )}
                </p>
              </div>
            </div>
          </div>
          <div
            id="system-requirements"
            className="row"
            style={{ marginTop: "40px" }}
          >
            <div className="col-xs-12 col-md-8 col-md-offset-2">
              <h3>{this.t("System Requirements for Deskreen")}</h3>
              <p>
                <strong>{`${this.t("Operating System")}: `}</strong>Windows,
                Linux, MacOS
              </p>
              <p>
                <strong>{`${this.t("Available Disk Space")}: `}</strong>210MB
              </p>
              <p>
                <strong>{`${this.t("RAM")}: `}</strong>
                {this.t(
                  "250MB on average to run app with one screen sharing session Every new screen sharing session will need upto 100MB of extra memory on average for smooth performance"
                )}
              </p>
              <p>
                <strong>{`${this.t("CPU")} (Windows): `}</strong>
                {this.t(
                  "any modern dual core CPU, weaker CPUs may have performance problems with current version on Windows We are going to fix it in future"
                )}
              </p>
              <p>
                <strong>{`${this.t("CPU")} (MacOS): `}</strong>
                {this.t("any modern CPU")}
              </p>
              <p>
                <strong>{`${this.t("CPU")} (Linux): `}</strong>
                {this.t(
                  "any modern CPU, even supports 1 core ARM, such as Raspberry PI"
                )}
              </p>
              <hr className="custom-hr-blue" />
            </div>
          </div>
          <div
            id="what-is-display-dummy-plug"
            className="row"
            style={{ marginTop: "40px" }}
          >
            <div className="col-xs-12 col-md-8 col-md-offset-2">
              <h3>
                {this.t(
                  "What is a Virtual Display Adapter? (aka Ghost or Virtual Display Plug or Display Dummy Plug)"
                )}
              </h3>
              <hr className="custom-hr-blue" />
              {generateNotSellingTheseNoteComponent(this.t)}
              <p>
                {this.t(
                  "It is a small plug that looks similar to USB Flash Drive When you plug them in to your computer, they are making your computer think that external display is connected Virtual Display Adapters are affordable and can be bought in online stores such as Amazon, Aliexpress, Ebay or even in one of your local tech store"
                )}
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-xs-12 col-md-8 col-md-offset-2">
              <h3>
                {this.t("Examples of how a Virtual Display Adapters look like")}
              </h3>
              <hr className="custom-hr-blue" />
              {generateNotSellingTheseNoteComponent(this.t)}
              <h3>
              {this.t("Please note that colors and sizes may differ for different manufacturers")}
              </h3>
              <div className="row">
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B07C4TWZRM&asins=B07C4TWZRM&linkId=fb25cc0f9ca1811d0f626f3e0226fb69&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B07FB64V4Y&asins=B07FB64V4Y&linkId=34c9a18b3ec20faef072c3a0180515b8&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B075PTQ4NH&asins=B075PTQ4NH&linkId=3510cf73eaa73d543c8d3152c2a73b53&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B07XBLVSYL&asins=B07XBLVSYL&linkId=6cfdcfefe9a16fcf95935f2aade26bec&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B086MKR9WH&asins=B086MKR9WH&linkId=8c36eb83487dcf4b64b6bcb9a91a9d1d&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B07YLR653H&asins=B07YLR653H&linkId=0181ae075ee8df577190a2b6d3b9c0af&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B073YCMYNW&asins=B073YCMYNW&linkId=68e2ebb2bc5f67383a1a729c4fdd7b17&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B072F13NCY&asins=B072F13NCY&linkId=ecb29a8db3016a0f3064e6fa832d23c3&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B0758675CJ&asins=B0758675CJ&linkId=88169b447574917e1b889f78d51782b9&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B0746HMLLG&asins=B0746HMLLG&linkId=ec5f4fe4013ee776eaeaec24327b5eb6&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B074FSDC25&asins=B074FSDC25&linkId=03ca140448237d171abd986d2ca41fcb&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
                <iframe
                  style={{
                    width: "120px",
                    height: "240px",
                    marginRight: "10px",
                    marginBottom: "60px",
                  }}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=deskreen-20&marketplace=amazon&amp;region=US&placement=B07YMKXDG1&asins=B07YMKXDG1&linkId=65fdb1cfe58d226a1df4a0f2788a9314&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
                ></iframe>
              </div>
              <div className="row">
                {generatePlugImageWithNameComponent(
                  "img/plugs/hdmi.jpg",
                  this.t("HDMI plug")
                )}
                {generatePlugImageWithNameComponent(
                  "img/plugs/dp.jpg",
                  this.t("DP plug")
                )}
                {generatePlugImageWithNameComponent(
                  "img/plugs/mini-dp.jpg",
                  this.t("Mini DP plug")
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(about);
