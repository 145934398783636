import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

export class Demos extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    return (
      <div id="video-demos" className="text-center" style={{ width: "100%" }}>
        <div className="container">
          <div className="section-title row">
            <h2>{this.t("VIDEO DEMONSTRATIONS")}</h2>
            <h3>
            {this.t("In the following videos we want to show you real examples of working with Deskreen")}
            </h3>
          </div>
          <div className="row">
            <hr className="custom-hr" />
            <h3 id="second-screen-demo-video" style={{ fontWeight: "900" }}>
            {`${this.t("Second Screen Demo Using Virtual Display Adapter")}:`}
            </h3>
            <div className="col-xs-12 col-md-8 col-md-offset-2">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/rmg5tZ4iSx8"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              </div>
              <p>{`${this.t("Alternative video link")}:`}</p>
              <video
                className="video-fluid"
                style={{ width: "100%", margin: "0 auto" }}
                preload="none"
                poster="img/poster/second-screen.jpg"
                controls
              >
                <source src="video/second-screen.mp4" type="video/mp4"></source>
              </video>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Demos);
