import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationUA from "./locales/ua/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationZH_CN from "./locales/zh-CN/translation.json";
import translationZH_TW from "./locales/zh-TW/translation.json";
import translationES from "./locales/es/translation.json";
import translationDE from "./locales/de/translation.json";
import translationDA from "./locales/da/translation.json";
import translationFI from "./locales/fi/translation.json";
import translationKO from "./locales/ko/translation.json";
import translationIT from "./locales/it/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationSV from "./locales/sv/translation.json";
import translationNL from "./locales/nl/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ua: {
    translation: translationUA,
  },
  ru: {
    translation: translationRU,
  },
  zh_CN: {
    translation: translationZH_CN,
  },
  zh_TW: {
    translation: translationZH_TW,
  },
  es: {
    translation: translationES,
  },
  de: {
    translation: translationDE,
  },
  da: {
    translation: translationDA,
  },
  fi: {
    translation: translationFI,
  },
  ko: {
    translation: translationKO,
  },
  it: {
    translation: translationIT,
  },
  ja: {
    translation: translationJA,
  },
  fr: {
    translation: translationFR,
  },
  sv: {
    translation: translationSV,
  },
  nl: {
    translation: translationNL,
  }
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
