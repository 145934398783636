import React, { Component } from "react";
import {
  faFeather,
  faAlignLeft,
  faUserFriends,
} from "@fortawesome/fontawesome-free-solid";
import { faWindowMaximize } from "@fortawesome/free-regular-svg-icons";
import { withNamespaces } from "react-i18next";

export class features extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    return (
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>{this.t("Deskreen Features")}</h2>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4 feature-with-icon">
              <a
                id="link-to-second-screen-demo-video"
                href="#second-screen-demo-video"
                className="page-scroll"
              >
                <div className="icon-features">
                  <i
                    className="fa fa-tablet"
                    style={{ transform: "rotate(90deg)" }}
                  ></i>
                  <i className="fa fa-laptop"></i>
                </div>
                <h3>{this.t("Second Screen")}</h3>
              </a>
              <p>
                {this.t(
                  "Use any device with a web browser as a second screen for your computer"
                )}{" "}
                <a
                  id="link-to-what-is-display-dummy-plug"
                  href="#what-is-display-dummy-plug"
                  className="page-scroll"
                >
                  {this.t("(with the help of Virtual Display Adapter)")}.
                </a>
              </p>
            </div>
            <div className="col-sm-6 col-md-4 feature-with-icon">
              <div className="icon-features">
                <i
                  className="fa fa-square"
                  style={{ transform: "translate(7px,7px)" }}
                ></i>
                <i
                  className="fa fa-square-o"
                  style={{ transform: "translate(-7px,-7px)" }}
                ></i>
              </div>
              <h3>{this.t("Share Screen View")}</h3>
              <p>
                {this.t(
                  "Share your computer entire screen to any device that has a web browser"
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 feature-with-icon">
              <div className="icon-features">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  height="40px"
                  style={{ fill: "white", transform: "translateX(2px)" }}
                >
                  <path d={faWindowMaximize.icon[4]} />
                </svg>
              </div>
              <h3>{this.t("Share App View")}</h3>
              <p>
                {this.t(
                  "Limit Deskreen to share only a single application view to any device with a web browser"
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 feature-with-icon">
              <div className="icon-features">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  height="40px"
                  style={{ fill: "white", transform: "translateX(2px)" }}
                >
                  <path d={faAlignLeft.icon[4]} />
                </svg>
              </div>
              <a href="#flip-screen-mode" className="page-scroll">
                <h3>{this.t("Teleprompter on Any Device")}</h3>
                <p>
                  {this.t(
                    "If you are a video blogger and you need a teleprompter, Flip Screen Mode is just for you"
                  )}
                </p>
              </a>
            </div>
            <div className="col-sm-6 col-md-4 feature-with-icon">
              <div className="icon-features">
                <i className="fa fa-wifi" style={{
                  transform: "translateY(5px)"
                }}></i>
              </div>
              <h3>{this.t("WiFi Compatible")}</h3>
              <p>
              {this.t("Deskreen can share screen over WiFi No cables needed")}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 feature-with-icon">
              <div className="icon-features" style={{ fontSize: "25px" }}>
                <div style={{ transform: "translateY(10px)" }}>
                  <i className="fa fa-tablet"></i>
                  <i className="fa fa-desktop"></i>
                  <i className="fa fa-mobile"></i>
                  <i className="fa fa-laptop"></i>
                </div>
              </div>
              <h3>{this.t("Multiple Connected Devices")}</h3>
              <p>
              {this.t("Connect as many devices as you want at the same time Share your screen to all of them")}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 feature-with-icon">
              <div className="icon-features">
                <i className="fa fa-magic" style={{
                  transform: "translateY(4px)"
                }}></i>
              </div>
              <h3>{this.t("Advanced Video Quality Control")}</h3>
              <p>
                {this.t("You can change picture quality while sharing a screen Auto quality change supported")}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 feature-with-icon">
              <div className="icon-features">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  height="40px"
                  style={{ fill: "white", transform: "translateX(2px)" }}
                >
                  <path d={faFeather.icon[4]} />
                </svg>
              </div>
              <h3>{this.t("Easy to Use")}</h3>
              <p>
              {this.t("Three easy steps to connect with Deskreen User oriented design with features that you need")}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 feature-with-icon">
              <div className="icon-features">
                <i className="fa fa-shield" style={{
                  transform: "translateY(4px)"
                }}></i>
              </div>
              <h3>{this.t("Secure")}</h3>
              <p>
              {this.t("Deskreen was built with security in mind It uses an industry standard of End-to-end encryption")}
              </p>
            </div>
            <div className="col-sm-6 col-md-offset-4 col-md-4 feature-with-icon">
              <div className="icon-features">
                <i className="fa fa-tachometer"></i>
              </div>
              <h3>{this.t("Fast")}</h3>
              <p>
                {this.t("Depending on your WiFi speed Deskreen works fast providing you with a good user experience")}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(features);
