import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

export class purpose extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }
  
  render() {
    return (
      <div id="testimonials">
        <div className="container">
          <div className="section-title text-center">
            <h2>{this.t("Why Deskreen?")}</h2>
            <h4 style={{ fontSize: '20px' }}>
              <strong>
                <em>Deskreen</em>
              </strong>{" "}
              {`- ${this.t("is a blend of words")} `}<strong>{`${this.t("'desktop' (desktop)")} `}</strong>{`${this.t("and")} `}
              <strong>{this.t("'screen' (screen)")}</strong>.
            </h4>
          </div>
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="testimonial">
                <div className="testimonial-image">
                  {" "}
                  <img src="img/team/01.png" alt="" />{" "}
                </div>
                <div className="testimonial-content">
                  <p>{`"${this.t("It all started with me wanting to use my iPad as a second screen for my Macbook. But I found that it is already outdated to use a MacOS built in feature called 'Sidecar' and I have to buy a new iPad. I also have a Windows PC and sometimes I needed to use my iPad as second screen for it as well. I tried to look for FREE solutions out there, but I could not find anything that would fit my requirements. Besides, all methods and software I found were different for Windows, Mac and Linux.  I got very confused about it. Then I got an idea of creating Deskreen. I decided to make it free and open-source to help people to solve this problem. Deskreen works the same for Windows, MacOS and Linux.")}`}
                  </p>
                  <p>...</p>
                  <p>{`${this.t("People nowadays are buying new devices very often and may have an old tablet, phone or laptop that they stopped using. If your old tablet, phone or laptop is still able to run a browser,  Deskreen can help you to bring a new life to your old digital buddy, and you can start using it as a second monitor for your laptop.")}"`}
                  </p>
                  <div className="testimonial-meta">
                    {" "}
                    <strong>- <a href="https://www.linkedin.com/in/pavlobu/" target="_blank">Pavlo (Paul) Buidenkov</a></strong>, <em>{this.t("Deskreen Creator")}</em>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(purpose);
