import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

import i18n from "../i18n";

function generateAppTranslatedBlockquoteWithButtonPrompt(t, buttonPrompt) {
  return (
    <blockquote className="blockquote warning-blockquote text-left">
      <span>
        <strong>
          {`${t("NOTE")}: `}
          <i>{`${t("language name")} ${t("language")} ${t(
            "exists in Deskreen app"
          )}. `}</i>
        </strong>
        {`${t("The instructions screenshots are in English")}. `}
      </span>
      {buttonPrompt ? <span>{buttonPrompt}</span> : <></>}
    </blockquote>
  );
}

export class Howtos extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    return (
      <div id="howtos" className="text-center" style={{ width: "100%" }}>
        <div className="container">
          <div className="section-title">
            <h2>{this.t("How to use Deskreen")}</h2>
            <h3>{this.t("THREE EASY STEPS")}</h3>
            <hr className="custom-hr-blue-how-to" />
            <div className="row">
              <div
                className="col-xs-12 col-sm-8 col-sm-offset-2"
                align="center"
              >
                <blockquote className="blockquote success-blockquote text-left">
                  <h4>
                    <strong>{`${this.t("NOTE")}: `}</strong>
                    {`${this.t(
                      "Deskreen shares your computer screen to a web browser"
                    )}. ${this.t("That means")} `}
                    <strong>
                      <em>{this.t("ANY")}</em>
                    </strong>
                    {` ${this.t(
                      "device, such as - phone, tablet, other computer or even smart TV, can be used to extend your computer screen while you are using Deskreen"
                    )}. `}
                    <strong>
                      <em>{this.t("ALSO")}</em>
                    </strong>{" "}
                    <strong>
                      {`${this.t(
                        "you can connect as many devices as you want! (phones, tablets, other computers, smart TVs etc.)"
                      )}`}
                    </strong>
                  </h4>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="center">
              <h3>{this.t("STEP ONE CONNECT")}</h3>
              <p className="howto-step-guide text-left">
                {this.t(
                  "When you have your computer with Deskreen running - Scan QR code with a tablet or phone, or type in web browser address bar manually what you see in prompt. That will open a page on your tablet with connection status."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(this.t)
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/1.jpg" />
              <p className="howto-step-guide text-left">
                {this.t(
                  "Click on QR code to make it bigger if your camera cant recognize a small a QR code"
                )}
              </p>
              <img className="img-responsive" src="img/steps/1-1.jpg" />
              <p className="howto-step-guide text-left">
                {this.t(
                  "If you don't have a camera to scan a QR code, click on blue button below QR code to copy address to clipboard. Then you can share copied address and paste it to web browser address bar to open Deskreen viewer. You can also type the address in blue button manually in browser address bar on any device to open Deskreen Viewer."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(
                  this.t,
                  <span>{this.t("Click to copy - Click to copy")}</span>
                )
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/1-2.jpg" />
              <p className="howto-step-guide text-left">
                {this.t(
                  "When you follow a link by QR code, or typing it manually in browser address bar, you will see message box like below. The address highlighted in green should match with what you see on your computer Deskreen app and device you try to connect with (eg. phone, tablet). If adresses match, then you can click Allow button. This will lead you to step two."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(
                  this.t,
                  <span>{this.t("Allow - Allow")}</span>
                )
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/2.jpg" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="center">
              <h3>{this.t("STEP TWO SELECT")}</h3>
              <p className="howto-step-guide text-left">
                {this.t(
                  "When you clicked 'Allow' button, you will be asked to choose what screen source you want to share. You will be prompted to select one of two options Share Entire Screen or Share Application Window. Like in an image below."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(
                  this.t,
                  <span>{`${this.t("Entire Screen - Entire Screen")}, ${this.t(
                    "Application Window - Application Window"
                  )}`}</span>
                )
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/3.jpg" />
              <h4>{this.t("STEP TWO (a) Application Window Sharing")}</h4>
              <p className="howto-step-guide text-left">
                {this.t(
                  "When you click on Application Window Button you can select what app window to share like in image below. Click on preview box and you will proceed to step three."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(
                  this.t,
                  <span>{`${this.t(
                    "Select App Window to Share - Select App Window to Share"
                  )}, ${this.t("Refresh - Refresh")}`}</span>
                )
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/4.jpg" />
              <h4>
                {this.t(
                  "STEP TWO (b) Entire Screen Sharing (Mirror Screen and Second Screen Use Cases)"
                )}
              </h4>
              <p className="howto-step-guide text-left">
                {this.t(
                  "If you want to share entire screen click on 'Entire Screen' button. You can choose to share entire screen when you need to mirror entire screen or use it as second screen, when Dummy Display Plug is connected. Click on preview box and you will proceed to step three."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(
                  this.t,
                  <span>{`${this.t(
                    "Select Entire Screen to Share - Select Entire Screen to Share"
                  )}, ${this.t("Refresh - Refresh")}`}</span>
                )
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/5.jpg" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="center">
              <h3>{this.t("STEP THREE CONFIRM")}</h3>
              <p className="howto-step-guide text-left">
                {this.t(
                  "This is a final step. Here you just double check, to make sure everything as you wanted. If you've changed your mind, you can go back, or even disconnect a device by clicking 'Connected info' green button."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(
                  this.t,
                  <span>{`${this.t("Confirm - Confirm")}, ${this.t(
                    "Connected - Connected"
                  )}`}</span>
                )
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/6.jpg" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="center">
              <h3>{this.t("DONE!")}</h3>
              <p className="howto-step-guide text-left">
                {this.t(
                  "After all done you will see 'Success!' message, and you can repeat procedure to connect one more device."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(
                  this.t,
                  <span>
                    {this.t("Connect New Device - Connect New Device")}
                  </span>
                )
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/7.jpg" />
              <p className="howto-step-guide text-left">
                {this.t(
                  "You will be able to view your computer screen in a web browser on other device. You can see example of application window sharing in the image below."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(this.t)
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/10.jpg" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="center">
              <h3>{this.t("Connected Devices Panel")}</h3>
              <p className="howto-step-guide text-left">
                {this.t(
                  "By clicking blue 'Connected Devices' button in top bar you will be able to disconnect one or all connected devices. In case of emergency, just quit Deskreen app, and all screen sharing sessions will stop immediately."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(
                  this.t,
                  <span>{`${this.t(
                    "Connected Devices - Connected Devices"
                  )}, ${this.t("Disconnect - Disconnect")}`}</span>
                )
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/8.jpg" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="center">
              <h3 id="flip-screen-mode">{this.t("Flip Screen Mode")}</h3>
              <p className="howto-step-guide text-left">
                {this.t(
                  "Here is a demonstration of how Flip Screen Mode works for teleprompter use case."
                )}
              </p>
              {i18n.language !== "en" ? (
                generateAppTranslatedBlockquoteWithButtonPrompt(
                  this.t,
                  <span>{this.t("Flip - Flip")}</span>
                )
              ) : (
                <></>
              )}
              <img className="img-responsive" src="img/steps/11.jpg" />
            </div>
          </div>
          <hr className="custom-hr-blue-how-to" />
          <h2 style={{ fontSize: "22px" }}>
            {this.t("Requirements and Permisions for Deskreen app")}
          </h2>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="center">
              <h3>
                <i className="fa fa-apple"></i>
                {` ${this.t(
                  "MacOS Permission to allow Deskreen to record screen"
                )}`}
              </h3>
              <blockquote className="blockquote warning-blockquote text-left">
                <strong>{`${this.t(
                  "Deskreen works via screen recording technology"
                )} `}</strong>
                {this.t(
                  "to live stream screen data to your tablet, phone. So you will have to provide a Screen Recording Permission for Deskreen on MacOS in order to allow Deskreen to access your screen."
                )}
              </blockquote>
              <blockquote className="blockquote warning-blockquote text-left">
                {
                  <span>{`${
                    i18n.language !== "en"
                      ? this.t("The instructions screenshots are in English") +
                        ". "
                      : ""
                  }${this.t(
                    "Screen Recording Permission on MacOS is in System Preferences -> Security & Privacy -> Privacy -> Screen Recording"
                  )}`}</span>
                }
              </blockquote>
              <img className="img-responsive" src="img/steps/9.jpg" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="center">
              <h3>
                <i className="fa fa-windows"></i>
                {` ${this.t(
                  "Windows Permission to allow Deskreen to access Local and Public Networks"
                )}`}
              </h3>
              <blockquote className="blockquote warning-blockquote text-left">
                <i className="fa fa-windows"></i>
                {` ${this.t(
                  "Windows users need to add 'Private Network Access' for Deskreen on first start or in firewall settings"
                )}`}
              </blockquote>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2" align="center">
              <h3>{this.t("Firewalls, antivirus etc.")}</h3>
              <blockquote className="blockquote warning-blockquote text-left">
                {this.t(
                  "Deskreen is not yet code signed. That means your antivirus will complain when you run it for the first time."
                ) + " "}
                <strong>
                  {this.t(
                    "Verification costs money. Please donate so we raise funds for making and keeping Deskreen code signed."
                  ) + " "}
                </strong>
                {this.t(
                  "You shold treat Deskreen as an exception for your malware protection software. Also Firewals both on your computer and WiFi router should not be blocking any Deskreen network access and activity. Otherwise Deskreen may not work smooth as expected."
                )}
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Howtos);
