import React, { Component } from "react";
import {
  faPatreon,
  faGithub,
  faOpenc,
} from "@fortawesome/free-brands-svg-icons";
import { withNamespaces } from 'react-i18next';
import {
  Link,
} from "react-router-dom";
import i18n from "../i18n";

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    const paramsPath = window.location.href.split("?")[1];

    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <h2 className="text-center">{this.t("Contribute to Deskreen")}</h2>
                <div className="col-md-6">
                  <button
                    id="donate-patreon-button-contribute-section"
                    type="button"
                    className="btn btn-custom btn-lg"
                    style={{
                      height: "50px",
                      width: "100%",
                      borderRadius: "50px",
                    }}
                  >
                    {" "}
                    <div className="col-xs-1 col-md-offset-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        height="22px"
                        style={{ fill: "rgb(255,29,29)" }}
                      >
                        <path d={faPatreon.icon[4]} />
                      </svg>
                    </div>
                    <div
                      className="col-xs-8"
                      style={{ transform: "translate(10px, 0px)" }}
                    >
                      <strong>{this.t("Donate on Patreon")}</strong>
                    </div>
                  </button>
                </div>
                <div className="col-md-6">
                  {/* <a href="https://opencollective.com/deskreen" target="_blank"> */}
                  <a href="https://twitter.com/Ukraine/status/1497294840110977024" target="_blank">
                    <button
                      id="donate-opencollective-button-contribute-section"
                      type="button"
                      className="btn btn-custom btn-lg"
                      style={{
                        height: "50px",
                        width: "100%",
                        borderRadius: "50px",
                      }}
                    >
                      {" "}
                      <div
                        className="col-xs-1 col-md-offset-1"
                        style={{ transform: "translateY(-3px)" }}
                      >
                        <svg
                          className="donate-opencollective-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          focusable="false"
                          // width="1em"
                          height="22px"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 256 256"
                        >
                          <path
                            d="M209.765 128.15c0 16.013-4.9 31.34-13.266 44.575l33.447 33.447a128.918 128.918 0 0 0 25.783-77.35c0-29.265-9.754-55.751-26.47-77.336l-32.776 32.729a79.442 79.442 0 0 1 13.267 43.888l.015.047z"
                            fill="#B8D3F4"
                          />
                          <path
                            d="M127.513 210.355c-45.367-.086-82.125-36.838-82.22-82.205c.069-45.386 36.835-82.166 82.22-82.252c16.732 0 32.058 4.901 44.607 13.267l32.744-32.776A128.965 128.965 0 0 0 127.514.636C57.123.636 0 57.76 0 128.15c0 70.359 57.124 127.513 127.513 127.513a125.126 125.126 0 0 0 77.351-26.501l-33.447-33.431c-11.861 9.754-27.157 14.624-43.904 14.624z"
                            fill="#7FADF2"
                          />
                        </svg>
                      </div>
                      <div
                        className="col-xs-8"
                        style={{ transform: "translate(10px, 0px)" }}
                      >
                        <strong>{this.t("Donate on Opencollective")}</strong>
                      </div>
                    </button>
                  </a>
                </div>
                <div className="col-md-6">
                  <button
                    id="contribute-on-contribute-section"
                    type="button"
                    className="btn btn-custom btn-lg"
                    style={{
                      height: "50px",
                      width: "100%",
                      // transform: "translateY(-15px)",
                      borderRadius: "50px",
                    }}
                  >
                    {" "}
                    <div className="col-xs-1 col-md-offset-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        height="25px"
                        style={{ fill: "black", transform: "translateY(-5px)" }}
                      >
                        <path d={faGithub.icon[4]} />
                      </svg>
                    </div>
                    <div
                      className="col-xs-7"
                      style={{ transform: "translate(10px, 0px)" }}
                    >
                      <strong>{this.t("Contribute on GitHub")}</strong>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="row" style={{ color: "white" }}>
              <div className="col-md-10 col-md-offset-1">
                <h2 className="text-center" style={{ fontSize: "23px" }}>
                  {this.t("Why your contribution is important to us")}
                </h2>
                <p>{this.t("Deskreen was made not to get any financial profit, but for benefit to the others. Deskreen is completely free forever. Also it is open-source, which means anyone can see the source code, to make sure it is not doing any harm to anyone. Deskreen was made completely out of good will and will be maintained by it.")}
                </p>
                <p>{this.t("If you contribute financially, it will help us to stay motivated to do bug fixes and add new features to make Deskreen even better.")}
                </p>
                <p>{this.t("If you are a blogger, please consider sharing Deskreen with your friends and subscribers.")}
                </p>
                <p>{this.t("If you are a developer, your contributions on our Github are kindly welcome.")}
                </p>
              </div>
            </div>
            <div className="row" style={{ color: "white" }}>
              <div className="col-md-10 col-md-offset-1">
                <h2 className="text-center" style={{ fontSize: "23px" }}>
                  {this.t("Our wishlist")}
                </h2>
                <div className="col-md-8 col-md-offset-2">
                  <div className="col-md-6">
                    <ul className="goals-list list-group text-left">
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;
                          <strong>
                            <em>{this.t("Code Signing")}</em> - ({this.t("Funds needed!")})
                          </strong>
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("Bug Fixes")}&nbsp;∞
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i>{" "}
                          &nbsp;{this.t("Maintenance")}&nbsp;∞
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("Save trusted devices (autoconnect)")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("Translating app to other languages")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;
                          <strong>
                          {this.t("Moving away from Virtual Display Adapter solution")} -{" "}
                            <a
                              href="https://github.com/pavlobu/deskreen/tree/master/drivers"
                              target="_blank"
                              style={{ color: "#00fff5", fontWeight: 700 }}
                            >
                              {this.t("(Support With Code Needed!)")}
                            </a>
                          </strong>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="goals-list list-group text-left">
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("Support more platforms (ex. RaspberryPI)")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("Autostart on login")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("Access through system tray")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("Remote control")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("Stylus support (for drawing)")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("CLI support for programmatic access")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-circle-o"></i> &nbsp;{this.t("And many many more...")}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <p style={{ fontSize: "21px" }}>{this.t("Your contribution will help us to achieve our goals faster. Depending on how active Deskreen's community is, we are going to implement the above listed features in future updates of Deskreen.")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              <Link
                id="deskreen-privacy"
                // to={"/lang-" + i18n.language + "/deskreen-privacy"}
                to={paramsPath ? `/lang-${i18n.language}/deskreen-privacy?${paramsPath}` : `/lang-${i18n.language}/deskreen-privacy`}
              >
                {this.t("Privacy Policy")}
              </Link>{" | "}
              <a
                id="deskreen-privacy"
                href="https://github.com/pavlobu/deskreen"
                target="_blank"
              >
                {this.t("Source Code")}
              </a>
            </p>
            <p>
            {this.t("This website is powered with")}{" "}
              <i
                className="fa fa-heart"
                style={{
                  fontSize: "20px",
                  color: "rgb(255,29,29)",
                  transform: "translateY(3px)",
                }}
              ></i>{" "}
              {this.t("from")}{" "}
              <a
                href="https://www.linkedin.com/in/pavlobu/"
                target="_blank"
                rel="nofollow"
              >
                Pavlo (Paul) Buidenkov{" "}
              </a>
              {`${this.t("using")}: `}
            </p>
            <p>
              <a
                href="https://github.com/issaafalkattan/React-Landing-Page-Template"
                target="_blank"
                rel="nofollow"
              >
                &copy; React Landing Page Template.
              </a>{" "}
              {this.t("Design by")}{" "}
              <a
                href="http://www.templatewire.com"
                target="_blank"
                rel="nofollow"
              >
                TemplateWire
              </a>
            </p>
            <p>
              <a
                id="pavlobu-copyright"
                href="https://www.linkedin.com/in/pavlobu/"
                target="_blank"
                rel="nofollow"
              >
                Pavlo (Paul) Buidenkov &copy;
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Contact);
