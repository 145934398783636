import React, { Component } from "react";
import detectBrowserLanguage from "detect-browser-language";
import { withNamespaces } from "react-i18next";
import { faChevronLeft } from "@fortawesome/fontawesome-free-solid";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";

import i18n from "./i18n";

import Navigation from "./components/navigation";
import Header from "./components/header";
import Features from "./components/features";
import About from "./components/about";
import Demos from "./components/demos";
import Howtos from "./components/howtos";
import Faq from "./components/faq";
import Purpose from "./components/purpose";
import Team from "./components/Team";
import Contact from "./components/contribute";
import JsonData from "./data/data.json";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      landingPageData: {},
    };
    this.t = props.t;
  }

  getlandingPageData() {
    this.setState({ landingPageData: JsonData });
  }

  componentDidMount() {
    this.getlandingPageData();

    this.doLanguageSet();

    // how to show ads on react app https://mao-tss.medium.com/fix-google-adsense-loading-issues-with-react-f338cbd61ac4
    // window.adsbygoogle = window.adsbygoogle || []
    // window.adsbygoogle.push({})
  }

  doLanguageSet() {
    const langPath = window.location.href.split(window.location.host)[1];

    if (langPath.startsWith("/lang-ru")) {
      if (i18n.language !== "ru") {
        i18n.changeLanguage("ru");
      }
      window.localStorage.setItem("i18nextLng", "ru");

    } else if (langPath.startsWith("/lang-ua")) {
      if (i18n.language !== "ua") {
        i18n.changeLanguage("ua");
      }
      window.localStorage.setItem("i18nextLng", "ua");

    } else if (langPath.startsWith("/lang-en")) {
      if (i18n.language !== "en") {
        i18n.changeLanguage("en");
      }
      window.localStorage.setItem("i18nextLng", "en");

    } else if (langPath.startsWith("/lang-es")) {
      if (i18n.language !== "es") {
        i18n.changeLanguage("es");
      }
      window.localStorage.setItem("i18nextLng", "es");

    } else if (langPath.startsWith("/lang-zh_CN")) {
      if (i18n.language !== "zh_CN") {
        i18n.changeLanguage("zh_CN");
      }
      window.localStorage.setItem("i18nextLng", "zh_CN");

    } else if (langPath.startsWith("/lang-zh_TW")) {
      if (i18n.language !== "zh_TW") {
        i18n.changeLanguage("zh_TW");
      }
      window.localStorage.setItem("i18nextLng", "zh_TW");

    } else if (langPath.startsWith("/lang-de")) {
      if (i18n.language !== "de") {
        i18n.changeLanguage("de");
      }
      window.localStorage.setItem("i18nextLng", "de");

    } else if (langPath.startsWith("/lang-da")) {
      if (i18n.language !== "da") {
        i18n.changeLanguage("da");
      }
      window.localStorage.setItem("i18nextLng", "da");

    } else if (langPath.startsWith("/lang-fi")) {
      if (i18n.language !== "fi") {
        i18n.changeLanguage("fi");
      }
      window.localStorage.setItem("i18nextLng", "fi");

    } else if (langPath.startsWith("/lang-ko")) {
      if (i18n.language !== "ko") {
        i18n.changeLanguage("ko");
      }
      window.localStorage.setItem("i18nextLng", "ko");

    } else if (langPath.startsWith("/lang-it")) {
      if (i18n.language !== "it") {
        i18n.changeLanguage("it");
      }
      window.localStorage.setItem("i18nextLng", "it");

    } else if (langPath.startsWith("/lang-ja")) {
      if (i18n.language !== "ja") {
        i18n.changeLanguage("ja");
      }
      window.localStorage.setItem("i18nextLng", "ja");

    } else if (langPath.startsWith("/lang-fr")) {
      if (i18n.language !== "fr") {
        i18n.changeLanguage("fr");
      }
      window.localStorage.setItem("i18nextLng", "fr");

    } else if (langPath.startsWith("/lang-sv")) {
      if (i18n.language !== "sv") {
        i18n.changeLanguage("sv");
      }
      window.localStorage.setItem("i18nextLng", "sv");

    } else if (langPath.startsWith("/lang-nl")) {
      if (i18n.language !== "nl") {
        i18n.changeLanguage("nl");
      }
      window.localStorage.setItem("i18nextLng", "nl");

    }
  }

  generateMainView() {
    this.doLanguageSet();

    return (
      <div>
        <Navigation />
        <Header data={this.state.landingPageData.Header} />
        <Features data={this.state.landingPageData.Features} />
        <About data={this.state.landingPageData.About} />
        <Demos />
        <Howtos />
        <Faq />
        <Purpose />
        <Team data={this.state.landingPageData.Team} />
        <Contact data={this.state.landingPageData.Contact} />
      </div>
    );
  }

  generatePrivacyPolicy() {
    const paramsPath = window.location.href.split("?")[1];

    return (
      <div className="container">
        <a
          href={
            paramsPath
              ? `${window.location.origin}?${paramsPath}`
              : window.location.origin
          }
        >
          <button
            className="btn btn-primary"
            type="button"
            style={{
              height: "50px",
              backgroundColor: "yellow",
              fontSize: "20px",
              color: "black",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              height="16px"
              style={{ fill: "black" }}
            >
              <path d={faChevronLeft.icon[4]} />
            </svg>
            {this.t("Go Back to Main Page")}
          </button>
        </a>
        <h1>{this.t("Deskreen privacy policy")}</h1>
        <p>
          {this.t(
            "Deskreen does not collect any personal information It only stores settings stored on your computer, but these are never sent outside your computer"
          )}
        </p>
        <p>
          {this.t(
            "IP addresses may be stored in logs for a limited time when updating the news banner and looking for updates, but it is not shared with anyone"
          )}
        </p>
      </div>
    );
  }

  render() {
    const paramsPath = window.location.href.split("?")[1];

    return (
      <>
        <Router>
          <Routes>
            <Route exact path="/lang-ua" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-ua/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-ru" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-ru/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-en" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-en/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-zh_CN" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-zh_CN/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-zh_TW" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-zh_TW/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-es" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-es/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-de" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-de/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-da" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-da/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-fi" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-fi/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-ko" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-ko/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-it" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-it/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-ja" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-ja/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-fr" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-fr/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-sv" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-sv/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />
            <Route exact path="/lang-nl" element={this.generateMainView()} />
            <Route
              exact
              path="/lang-nl/deskreen-privacy"
              element={this.generatePrivacyPolicy()}
            />


            <Route exact path="/lang-zh">
              <Navigate to="/" />
            </Route>
            <Route exact path="/">
              {(() => {
                const localStorageI18nextLng = window.localStorage.getItem(
                  "i18nextLng"
                );

                if (localStorageI18nextLng && localStorageI18nextLng !== "") {
                  if (localStorageI18nextLng === "en") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-en",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-en?${paramsPath}` : "/lang-en"}
                      />
                    );
                  } else if (localStorageI18nextLng === "ua") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ua",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-ua?${paramsPath}` : "/lang-ua"}
                      />
                    );
                  } else if (localStorageI18nextLng === "ru") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-ru?${paramsPath}` : "/lang-ru"}
                      />
                    );
                  } else if (localStorageI18nextLng === "zh_CN") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-zh_CN?${paramsPath}` : "/lang-zh_CN"}
                      />
                    );
                  } else if (localStorageI18nextLng === "zh_TW") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-zh_TW?${paramsPath}` : "/lang-zh_TW"}
                      />
                    );
                  } else if (localStorageI18nextLng === "es") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-es?${paramsPath}` : "/lang-es"}
                      />
                    );
                  } else if (localStorageI18nextLng === "de") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-de?${paramsPath}` : "/lang-de"}
                      />
                    );
                  } else if (localStorageI18nextLng === "da") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-da?${paramsPath}` : "/lang-da"}
                      />
                    );
                  } else if (localStorageI18nextLng === "fi") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-fi?${paramsPath}` : "/lang-fi"}
                      />
                    );
                  } else if (localStorageI18nextLng === "ko") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-ko?${paramsPath}` : "/lang-ko"}
                      />
                    );
                  } else if (localStorageI18nextLng === "it") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-it?${paramsPath}` : "/lang-it"}
                      />
                    );
                  } else if (localStorageI18nextLng === "ja") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-ja?${paramsPath}` : "/lang-ja"}
                      />
                    );
                  } else if (localStorageI18nextLng === "fr") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-fr?${paramsPath}` : "/lang-fr"}
                      />
                    );
                  } else if (localStorageI18nextLng === "sv") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-sv?${paramsPath}` : "/lang-sv"}
                      />
                    );
                  } else if (localStorageI18nextLng === "nl") {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-nl?${paramsPath}` : "/lang-nl"}
                      />
                    );
                  }
                }

                const browserLang = detectBrowserLanguage();

                if (browserLang && browserLang !== "") {
                  if (browserLang.toLowerCase().startsWith("en")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-en",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-en?${paramsPath}` : "/lang-en"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("uk")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ua",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-ua?${paramsPath}` : "/lang-ua"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("ru")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-ru",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-ru?${paramsPath}` : "/lang-ru"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("zh-cn")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-zh_CN?${paramsPath}` : "/lang-zh_CN"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("zh-tw")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-zh_TW?${paramsPath}` : "/lang-zh_TW"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("es")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-es?${paramsPath}` : "/lang-es"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("de")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-de?${paramsPath}` : "/lang-de"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("da")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-da?${paramsPath}` : "/lang-da"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("fi")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-fi?${paramsPath}` : "/lang-fi"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("ko")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-ko?${paramsPath}` : "/lang-ko"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("it")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-it?${paramsPath}` : "/lang-it"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("ja")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-ja?${paramsPath}` : "/lang-ja"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("fr")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-fr?${paramsPath}` : "/lang-fr"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("sv")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-sv?${paramsPath}` : "/lang-sv"}
                      />
                    );
                  } else if (browserLang.toLowerCase().startsWith("nl")) {
                    return (
                      <Navigate
                        // to={{
                        //   pathname: "/lang-zh",
                        // }}
                        // state={paramsPath}
                        to={paramsPath ? `/lang-nl?${paramsPath}` : "/lang-nl"}
                      />
                    );
                  }
                }

                return (
                  <Navigate
                    // to={{
                    //   pathname: "/lang-en",
                    // }}
                    // state={paramsPath}
                    to={paramsPath ? `/lang-en?${paramsPath}` : "/lang-en"}
                  />
                );
              })()}
            </Route>
          </Routes>
        </Router>
      </>
    );
  }
}

export default withNamespaces()(App);
