import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

import getLatestReleaseDownloadUrls from "../utils/getLatestReleaseDownloadUrl";

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { downloadUrls: undefined };
    this.t = props.t;
  }

  componentDidMount() {
    getLatestReleaseDownloadUrls().then((res) => {
      this.setState({
        downloadUrls: res,
      });
    });
  }

  render() {
    return (
      <header id="header" style={{ width: "100vw" }}>
        <div className="intro" style={{ width: "100vw" }}>
          <div className="overlay" style={{ width: "100vw" }}>
            <div className="container" style={{ width: "100vw" }}>
              <div
                className="row"
                style={{
                  height: "0px",
                }}
              >
                <a href="https://github.com/pavlobu/deskreen" target="_blank">
                  <img
                    id="fork-me-on-github"
                    src="https://raw.githubusercontent.com/tholman/github-corners/master/svg/github-corner-right.svg"
                    style={{
                      width: "120px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  />
                </a>
              </div>
              {/* <div className="row">
                <div className="col-12">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                  ></script>
                  {/*<!-- Best Travel Wi-Fi Router for Deskreen --> */}
                  {/* <ins
                    class="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-6468756664607050"
                    data-ad-slot="5348796307"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                  ></ins> */}
                  {/* <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                </script> */}
                {/* </div>
              </div> */}
              <div className="row ">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h2 className="ukraine-donate">Deskreen creator is a Ukrainian. 🇺🇦 Ukraine 🇺🇦 needs your help ❗️❗️❗️</h2>
                  <h2><a className="ukraine-donate-link" href="https://twitter.com/Ukraine/status/1497294840110977024"><b><i>CLICK HERE TO DONATE TO UKRAINE!</i></b></a></h2>
                  <h3 className="ukraine-donate">If you don't live in a cave and aware of what is going on in the world 🌍 , Russian 🇷🇺 government had started global armed invasion on the territory of Ukraine on the 24th of February 2022. <b><i>This is for real, this is a WAR. Russian army is killing Ukrainian soldiers, Ukrainian civil citizens and Ukrainian children RIGHT NOW because Russian government gave them an order to do so.</i></b> You can search online for thousands of videos of what is going on in Ukraine.</h3>
                  <h3 className="ukraine-donate">Ukrainians fight brave for their land and will never give up. But you must understand that our country is fighting here not for our land only, but for the safety of the whole world. <b><i>❗️❗️❗️ If Ukraine fails in this war with Russian army and Russian government, the security of all countries in the world 🌍 will be under the threat! Russian government and it's vicious allies and governments from other countries will be moving their armies to YOUR land, sooner or later ❗️❗️❗</i></b></h3>
                  <h3 className="ukraine-donate">You must understand that now Ukraine has more people here willing to fight than weapons, military supplies and other inventory for them.</h3>
                  <h3 className="ukraine-donate"><b>If you CAN and WANT to support Ukraine 🇺🇦 and Ukrainian army, here is a tweet with instructions from OFFICIAL ✅ account of Ukraine 🇺🇦</b></h3>
                  <h2 className="ukraine-donate"><a className="ukraine-donate-link" href="https://twitter.com/Ukraine/status/1497294840110977024"><b><i>CLICK HERE TO GO TO A TWEET TO DONATE TO UKRAINE!</i></b></a></h2>
                  <h2 className="ukraine-donate">GLORY TO UKRAINE, GLORY TO UKRAINIAN HEROES!</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <div className="row">
                    <img
                      src="img/deskreen-logo-icon_512x512.png"
                      style={{ width: 200, height: 200 }}
                    />
                  </div>
                  <h1>
                    {this.props.data ? "Deskreen" : "Loading"}
                    <span></span>
                  </h1>
                  <p>
                    {this.props.data
                      ? this.t(
                          "Turn any device into a secondary screen for your computer"
                        )
                      : "Loading"}
                  </p>
                  <img
                    src={`https://img.shields.io/github/downloads/pavlobu/deskreen/total?style=for-the-badge&label=${this.t(
                      "downloads"
                    )}`}
                    alt="total-downloads"
                    style={{
                      borderRadius: "100px",
                    }}
                  />
                  <p style={{ marginBottom: "20px" }}>
                    {this.t("Free Download")}:
                  </p>
                  {this.state.downloadUrls ? (
                    <a
                      href={this.state.downloadUrls.win}
                      className="btn btn-custom btn-lg page-scroll"
                      target="_blank"
                    >
                      <i className="fa fa-windows"></i> Windows
                    </a>
                  ) : (
                    <></>
                  )}
                  {this.state.downloadUrls ? (
                    <a
                      href={this.state.downloadUrls.mac}
                      className="btn btn-custom btn-lg page-scroll"
                      target="_blank"
                    >
                      <i className="fa fa-apple"></i> MacOS
                    </a>
                  ) : (
                    <></>
                  )}
                  {this.state.downloadUrls ? (
                    <a
                      href={this.state.downloadUrls.deb}
                      className="btn btn-custom btn-lg page-scroll"
                      target="_blank"
                    >
                      <i className="fa fa-linux"></i> Linux (.deb)
                    </a>
                  ) : (
                    <></>
                  )}
                  {this.state.downloadUrls ? (
                    <a
                      href={this.state.downloadUrls.appImage}
                      className="btn btn-custom btn-lg page-scroll"
                      target="_blank"
                    >
                      <i className="fa fa-linux"></i> Linux (.AppImage)
                    </a>
                  ) : (
                    <></>
                  )}
                  {this.state.downloadUrls ? (
                    <a
                      href="https://github.com/pavlobu/deskreen/releases/latest"
                      className="btn btn-custom btn-lg page-scroll"
                      target="_blank"
                    >
                      {this.t("Others")}
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withNamespaces()(Header);
