import axios from "axios";

const githubApiContributorsUrl =
  "https://api.github.com/repos/pavlobu/deskreen/contributors";

export default async function getGithubContributors() {
  const response = await axios({
    url: githubApiContributorsUrl,
    method: "get",
    headers: { "User-Agent": "node.js" },
  });

  return response.data;
}
